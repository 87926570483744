import { Modal, 
	Button, 
	Box,
	Checkbox,
	FormControlLabel
} from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import Cookies from "js-cookie";
import Help from "./Help";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4, 
  width: "95%", 
  height: "80vh", 
  overflowY: "auto",
};


function Onboarding({open, ...props}) {

	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(open);
	
	useEffect(() => {}, [open])
	
	const handleChangeCheckbox = (checked) => {
		if (checked) {
			
			Cookies.set("AIDATAME_ONBOARDING_COOKIE", true, {expires: 36500})
		}
	}
	
	const handleCloseButton = () => {
		setIsOpen(false)
	}
	
	return (
		<Modal
			open={isOpen}
		    onClose={() => setIsOpen(!isOpen)}
		    aria-labelledby="parent-modal-title"
		    aria-describedby="parent-modal-description"
			>
			<Box sx={style}
				>
			
			<Help />
			<FormControlLabel 
				control={<Checkbox 
							 
							onChange={(e) => handleChangeCheckbox(e.target.value)}
							/>
						} 
				label={t("doNotShowAgain")}
				
				/>
			<Box
				sx={{ 
				  display: "flex", 
				  justifyContent: "flex-end"
				}}
				>
			<Button variant="contained" 
				color="primary"
				onClick={() => {handleCloseButton()}}
				>{t("close")}
			</Button>
			</Box>
			</Box>
		</Modal>
	)
}

export default Onboarding;
