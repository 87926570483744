import { useNavigate } from "react-router-dom";
import { ListItemButton,
	ListItemText,
} from '@mui/material';

const Hit = ({hit}) => {
	let navigate = useNavigate();
	const handleClick = (item) => {
		navigate("/" + item.market + "/" + item.issuer_ticker)		
	}

	return (

		<ListItemButton
			onClick={(e) => handleClick(hit)}
			>
			<ListItemText 
				primary={hit.fullname}
				secondary={
					<>{hit.issuer_ticker}
						&nbsp;&bull;&nbsp;
						{hit.market}
						&nbsp;&bull;&nbsp;
						{hit.location}</>
					}
				/>
		</ListItemButton>
	)
};

export default Hit;

