import { useParams } from 'react-router-dom';
import { Container,
	LinearProgress,
	Alert,
	Link,
	Snackbar,
	Grow,
} from "@mui/material";
import SwipeIcon from '@mui/icons-material/Swipe';
import { TickerInfo,
	SwipeableScreen,
	ActionHint
} from "../components";
import { get } from "../components/Functions";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useTranslation, Trans } from "react-i18next";


function Ticker(props) {
	let { market, ticker } = useParams();
	
	const { t } = useTranslation();
	
	const [isLoaded, setIsLoaded] = useState(false);
	const [error, setError] = useState(null);
	const [item, setItem] = useState(null);
	const [status, setStatus] = useState(200);
  	const [open, setOpen] = useState(false);
	
	useEffect(() => {		
		
		let token = Cookies.get("AIDATAME_AUTH_TOKEN")
		if (token) {
			get("/api/v1/score/" + market + "?ticker=" + ticker, token)
			.then(res => {
				if (!res.isError) {
					setItem(res.data)
					setIsLoaded(true)
					setStatus(res.status)
					setOpen(true)
				} else {
					setError(res.error)
					setIsLoaded(true)
					setStatus(res.status)
				}
			})
		} else {
			setError(<Trans i18nKey="pleaseLoginToSeeScore"
					ns="translation"
					t={t}
					components={{
						LoginLink: <Link href={"/login"}>login</Link>,
						SignUpLink: <Link href={"/sign-up"}>sign-up</Link>,
					}}
						>
			</Trans>)
		}
	}, [market, ticker])
	
	return (
		<Container sx={{ height: "100%", marginBottom: "50px" }}>
			<br/>
			<TickerInfo market={market} ticker={ticker}/>
			
		
			
		{error
			? <div><br/><Alert severity="warning">{error}</Alert></div>
			: isLoaded
				? <div>	
					<SwipeableScreen item={item} market={market} status={status}
					{...props} />
					</div>
				: <LinearProgress />
		}
			

		</Container>
	)
}

export default Ticker;
