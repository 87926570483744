import { Card,
	CardHeader,
	CardContent,
	CardActions,
	Typography,
	Select,
	MenuItem,
	FormControl,
} from "@mui/material";
import QuickTimeRangeButtonGroup from "./QuickTimeRangeButtonGroup";
import TimeSeriesChart from "./TimeSeriesChart";
import { useState, useEffect } from "react";
import { get } from "./Functions";

function ChartContainer (props) {
	const [isLoaded, setIsLoaded] = useState(false);
	const [error, setError] = useState(null);
	const [items, setItems] = useState([]);
	const [selection, setSelection] = useState(null);
	const [ts, setTs] = useState(null);
	
	const maxLength = 20;
	
	useEffect(() => {		
		get(props.uri)
		.then(res => {
			if (!res.isError) {
				let k = Object.keys(res.data)[0]
				setTs(res.data)
				setSelection(Object.keys(res.data[k])[0])
				setItems(Object.keys(res.data[k]))
				setIsLoaded(true)
			} else {
				setError(res.error)
				setIsLoaded(true)
				
			}
		})}, [props.uri])	
		
	if (error) {
		return (<>{error}</>)
	} else if (!isLoaded) {
		return (<>Loading...</>)
	} else {
		
		return (
			<Card sx={{ marginBottom: "50pt" }}>
			<CardHeader 
				title={<Typography variant="h5">
						Chart
						</Typography>}
				subheader={null} 
				action={<FormControl size="small">
							<Select value={selection}
								onChange={(e) => setSelection(e.target.value)}>
								{items.map(i => (
									<MenuItem value={i} key={i}>
										{i.length > maxLength
											? i.substring(0, maxLength) + "..."
											: i
										}
									</MenuItem>
									))}
							</Select>
						</FormControl>
						} />
			
				<QuickTimeRangeButtonGroup />
			
			
				<TimeSeriesChart {...props} 
					items={ts} 
					k={selection}/>
			</Card>
		)
	}
}

export default ChartContainer;
