import { Paper,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Button,
	Typography,
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { put, post } from "./Functions";
import { useTranslation } from "react-i18next";


function Catalog({checkoutButton=false, item, onCheckoutSuccess, ...props}) {	
	
	const Paddle = window.Paddle;
	let navigate = useNavigate();
	const { t } = useTranslation();
	
	const features = {
		826004: ["ff1","ff2","ff3"],
		814544: ["pf1","pf2","pf3","pf4"],
		823086: ["pf1","pf2","pf3","pf4"],
	}
		
	const checkoutComplete = (data) => {
		
		let product = data.product
		let user = data.user
		let checkout = data.checkout

		Paddle.Order.details(checkout.id, function (orderDetail) {		
			put("/api/user/auth", 
				{
					paddle_id: parseInt(user.id), 
					plan_id: product.id, 
					subscription_id: orderDetail.order.subscription_id,
				},
				Cookies.get("AIDATAME_AUTH_TOKEN")
			).then(res => {
				if (!res.isError) {
					
					let user = res.data;
					delete user["password"]
					localStorage.setItem("user", JSON.stringify(user))
					onCheckoutSuccess(true)
					
					
					post("/api/user/token", 
						{email: user.email, expire: 24 * 3600},
						Cookies.get("AIDATAME_AUTH_TOKEN")
					).then(res => {
						if (!res.isError) {
							
							Cookies.set("AIDATAME_AUTH_TOKEN", res.data.token, {expires: 1})
						} else {
							console.log(res.error)
						}
					})
					
					
				} else {
					console.log(res.error)
				}
			})
		
		})
	}
	
	const openCheckout = (planId) => {
	
		if (planId) {
			Paddle.Checkout.open({ 
				product: planId,
				email: props.user.email,
				successCallback: checkoutComplete
			})
		} else {
			navigate("/sign-up")
		}

		
	}
	
	return (
		<Paper sx={{ width: "100%", 
				maxWidth: 400,
				paddingBottom: 5,
				paddingTop: 5,
				textAlign: "center",
				marginBottom: 2
				}}
			elevate={10}
			>
		<Typography variant="h5" sx={{ textAlign: "center" }}> 
			{item ? item.name : ""}
		</Typography>
			
		<List 
			disablePadding={true} 
			dense
			>
		{features[item.id].map(f => (
			<ListItem sx={{ paddingBottom: 0, paddingTop: 0 }}>
				<ListItemIcon ><CheckIcon /></ListItemIcon>
				<ListItemText>{t(f)}</ListItemText>
			</ListItem>
		))}
		</List>
		<Typography variant="h5" sx={{ textAlign: "center" }}> 
			€{item ? item.recurring_price.EUR : null}
		</Typography>
		
		<Typography sx={{ textAlign: "center", marginBottom: "10pt" }}> 
			{item 
				? item.billing_type==="month" 
					? t("perMonth")
					: t("perYear")
				: null
			}
		</Typography>


		{checkoutButton
			? <Button variant="contained" 
					onClick={() => openCheckout(item.id)}>
								Select
						</Button>
			: <></>
		}
		
		</Paper>
		
	)
}

export default Catalog;
