import { useTranslation, Trans } from "react-i18next";
import { Box, 
	Typography,
} from "@mui/material";
import Logo from "../assets/logo.svg";
import "../assets/App.css";


function Help({locale, ...props}) {
	const { t } = useTranslation()

	return (
		<Box sx={{marginTop: 3, 
					marginLeft: 3,
					marginRight: 3,
					maxWidth: "600px",}}>
			<div align="center"><Logo height={80}/></div>
			
			{locale==="de"
				? <div style={{ marginBottom : 25 }}
					>
				
			<Typography
				variant="h5"
				>AiData me - FAQ
			</Typography>
			
	<p><strong>Was bedeuten die Score-Farben für meine Kaufentscheidung?</strong></p>

	Grün<br/>

	Auf einer Skala von 0-9 werden die Werte 7-9 in der Farbe grün angezeigt, d.h. es gibt durchschnittlich gute bis sehr gute News im jeweiligen Bereich = positiv.<br/>

	Gelb<br/>

	Auf einer Skala von 0-9 werden die Werte 4-6 in der Farbe gelb angezeigt, d.h. es gibt durchschnittlich mäßige News im jeweiligen Bereich = Achtung / Vorsicht.<br/>

	Rot<br/>

	Auf einer Skala von 0-9 werden die Werte 1-3 in der Farbe rot angezeigt, d.h. es gibt durchschnittlich schlechte News im jeweiligen Bereich = negativ.<br/>

	Je größer der Anteil der jeweiligen Farbe, umso besser ist der Score für das ausgewählte Unternehmen.<br/>

	Rosa<br/>

	Auf einer Skala von 0-9 wird der Wert 0 und schlechter in der Farbe rosa angezeigt, d.h. es gibt überwiegend negative News im jeweiligen Bereich = negativ.<br/>

	Grau (unrankend)<br/>

	"Unranked" bedeutet, dass zu diesem speziellen SDG (oder ESG Pillar) keine News vorhanden sind.

	<p><strong>Warum unterscheiden sich die farblich dargestellten Bewertungen (rot-gelb-grün) vom Branchenranking?</strong></p>

	Die farblich dargestellten Bewertungen sind absolut für sich für das ausgewählte Unternehmen zu sehen. Das Branchenranking stellt hingegen das Unternehmen im Branchenvergleich dar.<br/>

	Innerhalb einer Branche ist das Unternehmen immer nur absolut zu sehen, da dieses auch in verschiedenen Branchen tätig sein kann.

	<p><strong>Ich erhalte für eine Suchanfrage mehrere Treffer zu dem Unternehmen. Ist dies korrekt?</strong></p>

	Bitte sende uns eine Nachricht mit dem Namen des Unternehmens an: kontakt@aidatachampions.com.

	<p><strong>Kann ich meine Zugangsdaten (Mailadresse) ändern?</strong></p>

	Es gibt keine Möglichkeit, die E-Mail-Adresse zu ändern. Es ist jedoch möglich, sich einfach ein neues Profil anzulegen.

	<p><strong>Wo kann ich mein Abo einsehen/ändern/kündigen?</strong></p>

	Unter dem Icon („Konto“), im Register Abo.

	<p><strong>Kann ich mein Abo vorzeitig kündigen?</strong></p>

	Die Abo-Laufzeiten sind extra kurz gehalten, damit niemand sein Abo vorzeitig kündigen muss. Deshalb gibt es diese Möglichkeit nicht.

	<p><strong>Welche Zahlungsmöglichkeiten gibt es?</strong></p>

	Du kannst zwischen den Zahlungsmöglichkeiten Paypal und Kreditkarte wählen.

	<p><strong>Kann ich mein Zahlungsmittel ändern?</strong></p>

	Eine Änderung des Zahlungsmittels für das laufende Abo ist nicht vorgesehen. Es ist jedoch möglich, sich ein neues Profil anzulegen und hierbei ein anderes Zahlungsmittel auszuwählen.

	<p><strong>Wo finde ich meine Rechnung?</strong></p>

	Deine Rechnung erhältst Du per E-Mail an Deine bei der Registrierung angegebene E-Mailadresse.

	<p><strong>Auf der Rechnung bin ich nicht als Rechnungsempfänger aufgeführt. Ist dies einstellbar?</strong></p>

	Ja, rufe hierzu Deine per E-Mail erhaltene Rechnung auf und wähle „Edit address / add VAT Number“. Nun ist die Eingabe Deiner vollständigen Adresse möglich.<br/>

	Benötigst Du den Beleg für Dein Unternehmen, aktiviere „This is a business purchase“. Jetzt kannst Du zusätzlich Firmennamen und VAT-Number erfassen.<br/>
				
				
				</div>
				: <></>
			}
			<Typography
				variant="h5"
				>AiData me - Onboarding
			</Typography>
			<Trans i18nKey="helpTextTitle" 
				ns="translation"
				t={t}
				>
			</Trans>
			<div>
			<iframe
				src={locale==="de" 
					? "https://www.youtube.com/embed/7rMm5yYymrc"
					: "https://www.youtube.com/embed/2dMIlfp-ciY"
				}
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
				title="Embedded youtube"
				/>
			</div>
			<div>
			<iframe
				src={locale==="de" 
					? "https://www.youtube.com/embed/20q7_4PDAY4"
					: "https://www.youtube.com/embed/lVR_qowYu3c"
				}
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
				title="Embedded youtube"
				/>
			</div>
			<Trans i18nKey="helpText" 
				ns="translation"
				transKeepBasicHtmlNodesFor={['strong','div','p']}
				t={t}
				>
			<p><strong><i>Search companies:</i></strong><br/>
			Use AiData me as your new search engine for company sustainability scores. Search from over 5,000 companies worldwide to find the company you want to buy from or invest in.</p>

			<p><strong><i>Receive hourly global news-based sustainability scores:</i></strong><br/> 
			After your search you will receive our AiData score. This score is determined by an artificial intelligence based algorithm. The algorithm has been trained since 2015 to identify positive and negative news about corporate sustainability and to qualify them hourly based on 2000 search terms, both SDG Goals and ESG criteria.<br/>

			These results are then used to determine our AiData score, so you always know how sustainable the companies you are looking for are acting.</p>

			<p><strong><i>Make a sustainable investment or purchase decision:</i></strong><br/>
			AiData me is available at an introductory price as a monthly subscription for only €4.99 or an annual plan for only €49.99.</p>

			<p><strong><i>Not just any tool, get reliable results with AiData me:</i></strong><br/>

			The results have been tested by scientists from renowned universities since 2015 and are consistently rated as being of very high quality.<br/>

			AiData me was developed and optimized by the founders of AIDATACHAMPIONS GmbH.</p>

			<p>We are convinced that only together with you we can make our world more sustainable for future generations.</p>

			<p>Therefore, use AiData me now to make sustainable purchasing and investment decisions today. Be part of our sustainable future now - <strong>Think sustAInably</strong>!</p>
			
			</Trans>
			
		</Box>
	)
}

export default Help;
