import { useEffect, useState } from "react";
import { Card,
	CardHeader,
	Avatar,
//	CardContent,
//	CardActions,
	Typography,
//	IconButton 
} from "@mui/material";
//import FavoriteIcon from '@mui/icons-material/Favorite';
import { get } from "./Functions";

function TickerInfo(props) {
	
	const [item, setItem] = useState(null);
//	const [error, setError] = useState(null);
//	const [isLoaded, setIsLoaded] = useState(false);
	
	useEffect(() => {
		get("/api/v1/info/" + props.market + "?ticker=" + props.ticker)
		.then(res => {
			if (!res.isError) {
				setItem(res.data)
//				setIsLoaded(true)
			} else {
//				setError(res.error)
//				setIsLoaded(true)
			}
		})
	}, [props.market, props.ticker])
	
	return (
	
		<Card>
		<CardHeader 
			avatar={
				<Avatar sx={{fontSize: "10pt"}}>{props.ticker}</Avatar>
			}
			title={item 
				? <Typography variant="h5">
					{item.FullName ? item.FullName.toUpperCase() : item.Name}
					</Typography> 
				: null}
			subheader={item ? item.Sector + " Sector" : null} />

		</Card>
		
	)
	
}

export default TickerInfo;
