import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function FBPixel() {
    const location = useLocation();
    
    useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'Lead');
    }
  }, [location]);

  return null;
}

export default FBPixel;
