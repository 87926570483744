import { GoBackHeader,
	Catalog,
	FBPixel 
} from "../components";
import { Stack, 
	Typography,
	Divider,
	Link
} from "@mui/material";
import { get } from "../components/Functions";
import { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

function Payment(props) {
	const { t } = useTranslation();

	const user = JSON.parse(localStorage.getItem("user"));
	const [items, setItems] = useState([]);
	const [update, setUpdate] = useState(false);
	
	useEffect(() => {
		get("/paddle")
		.then(res => {
			if (!res.isError) {
				setItems(res.data)
			} else {
				console.log(res.error)
			}
		})
	}, [update])
	
	
	if (user) {
		if (!user.plan_id) {
			user.plan_id = 826004;
		}
	} 
	
	return (
		<div id="fb-fire-pixel">
		<GoBackHeader title={t("subscriptionPlan")}/>
		
		<Stack sx={{ marginBottom: 10,
				marginTop: 1,
				marginLeft: 3,
				marginRight: 3,
				alignItems: "center", 
				justifyContent: "center"
				}} 
			>
		<Typography variant="h6">{t("yourCurrentPlan")}</Typography>
		<p>
		{user
			? items.filter(i => i.id===user.plan_id)
		.map(i => (<Catalog item={i} key={i.id}
							checkoutButton={false}
							user={user}
							/>
			))
			: <>{t("notSubscribed")}
		 &nbsp;
		<Trans i18nKey="pleaseLoginToSubscribe" 
			ns="translation"
			t={t}
			components={{
				LoginLink: <Link href={"/login"}>login</Link>,
				SignUpLink: <Link href={"/sign-up"}>sign-up</Link>
			}}
			>
			
		</Trans></>
		}
		</p>
		<Divider flexItem />
		<Typography variant="h6">{t("availablePlans")}</Typography>
		{user
			? items.filter(i => i.id!==user.plan_id)
		.map(i => (<Catalog item={i} key={i.id}
							checkoutButton={i.name==="Free" ? false : true}
							user={user}
							onCheckoutSuccess={setUpdate}
							onCancelSuccess={setUpdate}
							/>
			))
			: items.map(i => (<Catalog item={i} key={i.id}
							checkoutButton={false}
							/>
			))
		}
		</Stack>
		<FBPixel/>
		</div>
	)
	
}

export default Payment;
