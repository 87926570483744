
import { Card,
	CardContent,
	Link,
	LinearProgress
} from "@mui/material";
import { useTranslation, Trans } from "react-i18next";

function SubscribedItem({item, onCancelSuccess, locale, ...props}) {
	
	const Paddle = window.Paddle;
	const options = {month: "short", day: "numeric", year: "numeric"};
	const { t } = useTranslation();
	
	const handleCancelSuccess = (data) => {
		onCancelSuccess(true)
	}

	
	const cancelNextPayment = (url) => {
		Paddle.Checkout.open({
			override: url,
			successCallback: handleCancelSuccess
		})
	}
	
	if (item && item.state==="active") {
		return (
			<Card>
			<CardContent>
			<div>{t("yourNextPmt")} {new Date(item.next_payment.date).toLocaleDateString(locale,options)}</div>
			
			<Link onClick={() => cancelNextPayment(item.cancel_url)}
				>
				{t("cancelSubscription")}
			</Link>
			</CardContent>
			</Card>
		)
	} else if (item && item.state==="deleted") {
		let endDate = new Date(item.last_payment.date)
		endDate.setMonth(endDate.getMonth() + 1)
		return (
			<Card>
			<CardContent>
			<div>
			<Trans i18nKey="yourPlanWillEnd"  
					ns="translation"
					t={t}
					>
				Your subscription plan will end on 
			</Trans>
			{endDate.toLocaleDateString(locale,options)}
			</div>
			
			</CardContent>
			</Card>
			
		)
	} else {
		return (
			<Card sx={{ minHeight: "80px"}}>
				<LinearProgress />
			</Card>
		)
	
	}
}

export default SubscribedItem;
