import { Container,
	List,
	ListSubheader,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Divider,
	Button,
	LinearProgress
} from "@mui/material";
import PaymentIcon from '@mui/icons-material/Payment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';
import DescriptionIcon from '@mui/icons-material/Description';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import CookieIcon from '@mui/icons-material/Cookie';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { SubscribedItem } from "../components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { post } from "../components/Functions";
import Cookies from "js-cookie";

function AccountManagement(props) {
	let navigate = useNavigate();
	const { t } = useTranslation();
	const [planDetail, setPlanDetail] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [error, setError] = useState(null);
	
	let user = JSON.parse(localStorage.getItem("user"));
	
	const handleLogout = () => {
		localStorage.removeItem("user"); 
		Cookies.remove('AIDATAME_AUTH_TOKEN', { path: '/' }) // removed!
		navigate("/login")
	}
	
	useEffect(() => {
		
		if ((user) && (user.subscription_id)) {
			post("/paddle", {
				"subscription_id": user.subscription_id,
				"plan_id": user.plan_id
				},
				
			).then(res => {
				if (!res.isError) {
					setPlanDetail(res.data)
					setIsLoaded(true)
				} else {
					setIsLoaded(true)
					setError(res.error)
				}
			})
		} else {
			setIsLoaded(true)
			
		}
		
	}, [])
	

	return (
		<Container >
		<List>
			<ListSubheader>{t("account")}</ListSubheader>
			<ListItem disablePadding>
				<ListItemButton >
				<ListItemIcon>
					<AccountCircleIcon />
				</ListItemIcon>
				<ListItemText primary={t("profile")} 
					secondary={user ? user.email : ""}/>
				</ListItemButton>
			</ListItem>
			
			<ListItem disablePadding>
				<ListItemButton onClick={() => navigate("/payment")}>
				<ListItemIcon>
					<PaymentIcon />
				</ListItemIcon>
				{error
					? <>{error}</>
					: isLoaded
						? <ListItemText primary={t("subscriptionPlan")} 
							secondary={user 
									? <>{t("youHaveSubscribedTo")} {planDetail ? planDetail.plan.name : "Free"} Plan</>
									: <>{t("notSubscribed")}</>
								}
							/>
						: <ListItemText primary={t("subscriptionPlan")} 
							secondary={<LinearProgress />}
							/>
					
				}
				</ListItemButton>
				
			</ListItem>	
			{planDetail
				? <SubscribedItem item={planDetail}
					{...props}
					/>
				: <div></div>
			}	
			<br/>
			<Divider />
			<ListSubheader>Legal Policies</ListSubheader>
			
			<ListItem disablePadding>
				<ListItemButton onClick={() => navigate("/terms-of-service")}>
				<ListItemIcon>
					<DescriptionIcon />
				</ListItemIcon>
				
				<ListItemText primary="Terms of Service" />

				</ListItemButton>
				
			</ListItem>
			
			<ListItem disablePadding>
				<ListItemButton onClick={() => navigate("/privacy-policy")}>
				<ListItemIcon>
					<PrivacyTipIcon />
				</ListItemIcon>
				
				<ListItemText primary="Privacy Policy" />

				</ListItemButton>
				
			</ListItem>
			
			<ListItem disablePadding>
				<ListItemButton onClick={() => navigate("/cookie-policy")}>
				<ListItemIcon>
					<CookieIcon />
				</ListItemIcon>
				
				<ListItemText primary="Cookie Policy" />

				</ListItemButton>
			</ListItem>
			
			<ListItem disablePadding>
				
				<ListItemButton
					onClick={(e) => navigate("/about")}
					>
				<ListItemIcon>
					<InfoIcon />
				</ListItemIcon>
				<ListItemText primary={t("about")} />
				</ListItemButton>
			</ListItem>
			{user && user.is_admin
				? <>
					<Divider />
					<ListSubheader>Admin</ListSubheader>
					
					<ListItem disablePadding>
						
						<ListItemButton
							onClick={(e) => navigate("/admin")}
							>
						<ListItemIcon>
							<AdminPanelSettingsIcon />
						</ListItemIcon>
						<ListItemText primary={"Manage users"} />
						</ListItemButton>
					</ListItem>
					</>
				: <></>
			}
		</List>
		<Button variant="contained" 
			fullWidth={true}
			onClick={(e) => {user 
				? handleLogout()
				: navigate("/login")}}
			>{user ? t("logOut") : t("login")}
		</Button>
		</Container>
	)
}

export default AccountManagement ;
