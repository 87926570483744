import { useEffect} from 'react';

function getCurrentTimestamp() {
	return Math.floor(Date.now() / 1000)
}



const IdleTimeOutHandler = ({onActive, onIdle, ...props})=>{
	let timer = undefined;
	const events= ['click','scroll','load','keydown']
	
	const timeOut = 60000;

	const eventHandler =(eventType) =>{

		localStorage.setItem('lastInteractionTime', getCurrentTimestamp() )
		
		if (timer){
			startTimer();
		}
	};
	
	const handleLogout = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("lastInteractionTime");
	}
    
   const addEvents=()=>{
        
        events.forEach(eventName=>{
            window.addEventListener(eventName,eventHandler)
        })
      startTimer();
        
        
    }
    const removeEvents=()=>{
        events.forEach(eventName=>{
            window.removeEventListener(eventName,eventHandler)
        })
    };

	useEffect(()=>{
		addEvents();

		return (()=>{

			removeEvents();
		})
	},[])

    
    const startTimer=()=>{
        
        timer = setTimeout(()=> {
            
            let lastInteractionTime = localStorage.getItem('lastInteractionTime')
            const diff = getCurrentTimestamp() - lastInteractionTime;
            let timeOutInterval = props.timeOutInterval ? props.timeOutInterval : timeOut;
            
            if(diff < timeOutInterval){
                startTimer();
                onActive();
            } else {
            	onIdle();
            	handleLogout();
            }
        }, props.timeOutInterval ? props.timeOutInterval : timeOut)
        
        
    }
    
    return(
        <div></div>
        )
        
    }
    
export default IdleTimeOutHandler;
