import { List,
	ListSubheader,
	ListItem,
	Select,
	MenuItem,
	Switch,
	FormControlLabel,
	Link
} from "@mui/material";
import { useTranslation } from "react-i18next";
//import { useContext } from "react";
//import LocaleContext from "../contexts/LocaleContext";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import LanguageIcon from '@mui/icons-material/Language';
import { styled } from '@mui/material/styles';


const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
  	margin-right: 60pt;
  }

`;

function SettingSidebar ({theme, setTheme, locale, setLocale, ...props}) {
	const { t, i18n } = useTranslation();
	
	
	const changeLocale = (l) => {
		if (locale !== l) {
			i18n.changeLanguage(l);
			setLocale(l)
			document.documentElement.lang = l;
		}
	}
	
	const handleConsentChange = () => {
		window.displayPreferenceModal();
		return false;
	}
	
	return (
		<List
			sx={{minWidth: "300px"}}
			subheader={			
			<ListSubheader>{t("settings")}</ListSubheader>
			}>
		<ListItem >
			<LanguageIcon />
			<StyledFormControlLabel 
				label={t("language")}
				labelPlacement="start"
				control={
					<Select
						variant="standard"
						value={locale}
						onChange={(e) => changeLocale(e.target.value)}
						>
						<MenuItem value={"en"}>EN</MenuItem>
						<MenuItem value={"fr"}>FR</MenuItem>
						<MenuItem value={"de"}>DE</MenuItem>
					</Select>
					}
				/>
		</ListItem>
		
		<ListItem>
			<Brightness4Icon />
			<StyledFormControlLabel
				label={t("darkMode")}
				labelPlacement="start"
				control={
					<Switch 
						checked={theme} 
						onClick={(e) => setTheme(!theme)}
						/>
					}
				
				/>
			
		</ListItem>
		<ListItem divider>
			<Link href="#"
				onClick={() => handleConsentChange()}
				id="termly-consent-preferences"
				nderline="none"
				>
				Consent Preferences
			</Link>
		</ListItem>
		
		<ListItem >App version 2023.06.18-03</ListItem>
		</List>
	)
}

export default SettingSidebar;
