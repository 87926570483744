import { Stack,
	Divider,
	Typography
} from "@mui/material";

import ESGIcon from "../assets/ESG_icon_01_2023.svg";
import SDGIcon from "../assets/SDG_icon_01_2023.svg";
import EIcon from "../assets/ESG_01_E_2023.svg";
import SIcon from "../assets/ESG_02_S_2023.svg";
import GIcon from "../assets/ESG_03_G_2023.svg";

import SDGIcon1 from "../assets/SDG_icon_01NP_2023.svg";
import SDGIcon2 from "../assets/SDG_icon_02ZH_2023.svg";
import SDGIcon3 from "../assets/SDG_icon_03GA_2023.svg";
import SDGIcon4 from "../assets/SDG_icon_04QE_2023.svg";
import SDGIcon5 from "../assets/SDG_icon_05GE_2023.svg";
import SDGIcon6 from "../assets/SDG_icon_06CA_2023.svg";
import SDGIcon7 from "../assets/SDG_icon_07AC_2023.svg";
import SDGIcon8 from "../assets/SDG_icon_08DE_2023.svg";
import SDGIcon9 from "../assets/SDG_icon_09IA_2023.svg";
import SDGIcon10 from "../assets/SDG_icon_10RI_2023.svg";
import SDGIcon11 from "../assets/SDG_icon_11SA_2023.svg";
import SDGIcon12 from "../assets/SDG_icon_12RCA_2023.svg";
import SDGIcon13 from "../assets/SDG_icon_13CA_2023.svg";
import SDGIcon14 from "../assets/SDG_icon_14LW_2023.svg";
import SDGIcon15 from "../assets/SDG_icon_15LL_2023.svg";
import SDGIcon16 from "../assets/SDG_icon_16PAI_2023.svg";
import SDGIcon17 from "../assets/SDG_icon_17PF_2023.svg";

import ScoreIcon from "./ScoreIcon";
import ScoreDescription from "./ScoreDescription";
import { useTranslation } from "react-i18next";

import "../assets/App.css";

import { useEffect, useRef } from "react";

function ScoreItem({icon, iconInfo, descInfo, item, ...props}) {
	return (
		<Stack direction={"row"}
			justifyContent="flexStart"
			alignItems="center"
			spacing={3}
			>
			<ScoreIcon icon={icon}
				value={item.value}
				info={iconInfo}
				{...props}
				/>
			<ScoreDescription rank={item.rank} 
				topN={item.topN ? item.topN : []}
				bottomN={item.bottomN ? item.bottomN : []}
				info={descInfo}
				{...props}
				/>
			
		
		</Stack>
	)
}



function SwipeableScreen ({item, ...props}) {
    
    const size = 120;
    
	const swiperRef = useRef(null);
	
	const { t } = useTranslation();
	
	useEffect(() => {
		const swiperContainer = swiperRef.current;
		const params = {
			autoHeight: true,
			injectStyles: [`	
			:root {
				--swiper-pagination-top: 8px;
				--swiper-pagination-bottom: auto;
			}
					
			.swiper-pagination-bullet {
			  width: 15px;
			  height: 15px;
			  background: #9C9E9F;
			}

			.swiper-pagination-bullet-active {
			  background: #81ac1c;
			}
			`],
			pagination: {
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '">' +  '</span>';
				},
			}
		}
		
	    Object.assign(swiperContainer, params);
	    swiperContainer.initialize();
	}, [])
	
	return (
		<swiper-container ref={swiperRef} init="false">
			<swiper-slide>
				<Stack direction={"column"} 
					spacing={0}
					sx={{ width: "100%", marginTop: 5 }}
					>
					<Typography variant="h6" 
						sx={{ fontStyle: "italic" }}
						>
						ESG Scores
					</Typography>
					<Divider />
						
					<ScoreItem icon={ESGIcon} size={size}
						item={{
							value: item["ESG Score"],  
							rank: item["ESG Score Rank"], 
							topN: item["ESG Score Top 3"],
							bottomN: item["ESG Score Bottom 3"]
						}}
						iconInfo={t("esg0")}
						descInfo={t("esgAvg")}
						{...props}
						/>
					
					<Divider />
								
					<ScoreItem icon={EIcon} size={size}
						item={{
							value: item["E Score"],
							rank: item["E Score Rank"],
							topN: item["E Score Top 3"],
							bottomN: item["E Score Bottom 3"]
						}}
						iconInfo={t("esg1")}
						{...props}
						/>
					<Divider />
											
					<ScoreItem icon={SIcon} size={size}
						item={{
							value: item["S Score"],
							rank: item["S Score Rank"],
							topN: item["S Score Top 3"],
							bottomN: item["S Score Bottom 3"]
						}}
						iconInfo={t("esg2")}
						{...props}
						/>
					<Divider />
											
					<ScoreItem icon={GIcon} size={size}
						item={{
							value: item["G Score"],
							rank: item["G Score Rank"],
							topN: item["G Score Top 3"],
							bottomN: item["G Score Bottom 3"]
						}}
						iconInfo={t("esg3")}
						{...props}
						/>
					<br/>
				</Stack>
			</swiper-slide>
			
			
			<swiper-slide>
				<Stack direction={"column"} 
					spacing={0}
					sx={{ width: "100%", marginTop: 5 }}
					>
					<Typography variant="h6" 
						sx={{ fontStyle: "italic" }}
						>
						SDG Scores
					</Typography>
					<Divider />
						
											
					<ScoreItem icon={SDGIcon} size={size}
						item={{
							value: item["SDG Score"],
							rank: item["SDG Score Rank"],
							topN: item["SDG Score Top 3"],
							bottomN: item["SDG Score Bottom 3"]
						}}
						iconInfo={t("sdg0")}
						descInfo={t("sdgAvg")}
						{...props}
						/>
						
					<Divider />
														
					<ScoreItem icon={SDGIcon1} size={size}
						item={{
							value: item["NO_POVERTY Score"],
							rank: item["NO_POVERTY Score Rank"],
							topN: item["NO_POVERTY Score Top 3"],
							bottomN: item["NO_POVERTY Score Bottom 3"]
						}}
						iconInfo={t("sdg1")}
						{...props}
						/>
						
					<Divider />
														
					<ScoreItem icon={SDGIcon2} size={size}
						item={{
							value: item["ZERO_HUNGER Score"],
							rank: item["ZERO_HUNGER Score Rank"],
							topN: item["ZERO_HUNGER Score Top 3"],
							bottomN: item["ZERO_HUNGER Score Bottom 3"]
						}}
						iconInfo={t("sdg2")}
						{...props}
						/>
						
						
					<Divider />
														
					<ScoreItem icon={SDGIcon3} size={size}
						item={{
							value: item["GOODHEALTH_AND_WELLBEING Score"],
							rank: item["GOODHEALTH_AND_WELLBEING Score Rank"],
							topN: item["GOODHEALTH_AND_WELLBEING Score Top 3"],
							bottomN: item["GOODHEALTH_AND_WELLBEING Score Bottom 3"]
						}}
						iconInfo={t("sdg3")}
						{...props}
						/>
						
					<Divider />
														
					<ScoreItem icon={SDGIcon4} size={size}
						item={{
							value: item["QUALITY_EDUCATION Score"],
							rank: item["QUALITY_EDUCATION Score Rank"],
							topN: item["QUALITY_EDUCATION Score Top 3"],
							bottomN: item["QUALITY_EDUCATION Score Bottom 3"]
						}}
						iconInfo={t("sdg4")}
						{...props}
						/>
						
					<Divider />
														
					<ScoreItem icon={SDGIcon5} size={size}
						item={{
							value: item["GENDER_EQUALITY Score"],
							rank: item["GENDER_EQUALITY Score Rank"],
							topN: item["GENDER_EQUALITY Score Top 3"],
							bottomN: item["GENDER_EQUALITY Score Bottom 3"]
						}}
						iconInfo={t("sdg5")}
						{...props}
						/>
						
					<Divider />
														
					<ScoreItem icon={SDGIcon6} size={size}
						item={{
							value: item["CLEAN_WATER_AND_SANITATION Score"],
							rank: item["CLEAN_WATER_AND_SANITATION Score Rank"],
							topN: item["CLEAN_WATER_AND_SANITATION Score Top 3"],
							bottomN: item["CLEAN_WATER_AND_SANITATION Score Bottom 3"]
						}}
						iconInfo={t("sdg6")}
						{...props}
						/>
						
					<Divider />
														
					<ScoreItem icon={SDGIcon7} size={size}
						item={{
							value: item["AFFORDABLE_AND_CLEAN_ENERGY Score"],
							rank: item["AFFORDABLE_AND_CLEAN_ENERGY Score Rank"],
							topN: item["AFFORDABLE_AND_CLEAN_ENERGY Score Top 3"],
							bottomN: item["AFFORDABLE_AND_CLEAN_ENERGY Score Bottom 3"]
						}}
						iconInfo={t("sdg7")}
						{...props}
						/>
						
					<Divider />
														
					<ScoreItem icon={SDGIcon8} size={size}
						item={{
							value: item["DECENT_WORK_AND_ECONOMIC_GROWTH Score"],
							rank: item["DECENT_WORK_AND_ECONOMIC_GROWTH Score Rank"],
							topN: item["DECENT_WORK_AND_ECONOMIC_GROWTH Score Top 3"],
							bottomN: item["DECENT_WORK_AND_ECONOMIC_GROWTH Score Bottom 3"]
						}}
						iconInfo={t("sdg8")}
						{...props}
						/>
						
					<Divider />
														
					<ScoreItem icon={SDGIcon9} size={size}
						item={{
							value: item["INDUSTRY_INNOVATION_AND_INFRASTRUCTURE Score"],
							rank: item["INDUSTRY_INNOVATION_AND_INFRASTRUCTURE Score Rank"],
							topN: item["INDUSTRY_INNOVATION_AND_INFRASTRUCTURE Score Top 3"],
							bottomN: item["INDUSTRY_INNOVATION_AND_INFRASTRUCTURE Score Bottom 3"]
						}}
						iconInfo={t("sdg9")}
						{...props}
						/>
						
					<Divider />
														
					<ScoreItem icon={SDGIcon10} size={size}
						item={{
							value: item["REDUCE_INEQUALITY Score"],
							rank: item["REDUCE_INEQUALITY Score Rank"],
							topN: item["REDUCE_INEQUALITY Score Top 3"],
							bottomN: item["REDUCE_INEQUALITY Score Bottom 3"]
						}}
						iconInfo={t("sdg10")}
						{...props}
						/>
						
					<Divider />
														
					<ScoreItem icon={SDGIcon11} size={size}
						item={{
							value: item["SUSTAINABLE_CITIES_AND_COMMUNITIES Score"],
							rank: item["SUSTAINABLE_CITIES_AND_COMMUNITIES Score Rank"],
							topN: item["SUSTAINABLE_CITIES_AND_COMMUNITIES Score Top 3"],
							bottomN: item["SUSTAINABLE_CITIES_AND_COMMUNITIES Score Bottom 3"]
						}}
						iconInfo={t("sdg11")}
						{...props}
						/>
						
					<Divider />
														
					<ScoreItem icon={SDGIcon12} size={size}
						item={{
							value: item["RESPONSIBLE_CONSUMPTION_PRODUCTION Score"],
							rank: item["RESPONSIBLE_CONSUMPTION_PRODUCTION Score Rank"],
							topN: item["RESPONSIBLE_CONSUMPTION_PRODUCTION Score Top 3"],
							bottomN: item["RESPONSIBLE_CONSUMPTION_PRODUCTION Score Bottom 3"]
						}}
						iconInfo={t("sdg12")}
						{...props}
						/>
						
					<Divider />
														
					<ScoreItem icon={SDGIcon13} size={size}
						item={{
							value: item["CLIMATE_ACTION Score"],
							rank: item["CLIMATE_ACTION Score Rank"],
							topN: item["CLIMATE_ACTION Score Top 3"],
							bottomN: item["CLIMATE_ACTION Score Bottom 3"]
						}}
						iconInfo={t("sdg13")}
						{...props}
						/>
						
					<Divider />
														
					<ScoreItem icon={SDGIcon14} size={size}
						item={{
							value: item["LIFE_BELOW_WATER Score"],
							rank: item["LIFE_BELOW_WATER Score Rank"],
							topN: item["LIFE_BELOW_WATER Score Top 3"],
							bottomN: item["LIFE_BELOW_WATER Score Bottom 3"]
						}}
						iconInfo={t("sdg14")}
						{...props}
						/>
						
					<Divider />
														
					<ScoreItem icon={SDGIcon15} size={size}
						item={{
							value: item["LIFE_ON_LAND Score"],
							rank: item["LIFE_ON_LAND Score Rank"],
							topN: item["LIFE_ON_LAND Score Top 3"],
							bottomN: item["LIFE_ON_LAND Score Bottom 3"]
						}}
						iconInfo={t("sdg15")}
						{...props}
						/>
					<Divider />
														
					<ScoreItem icon={SDGIcon16} size={size}
						item={{
							value: item["PEACE_JUSTUCE_AND_STRONG_INSTITUTION Score"],
							rank: item["PEACE_JUSTUCE_AND_STRONG_INSTITUTION Score Rank"],
							topN: item["PEACE_JUSTUCE_AND_STRONG_INSTITUTION Score Top 3"],
							bottomN: item["PEACE_JUSTUCE_AND_STRONG_INSTITUTION Score Bottom 3"]
						}}
						iconInfo={t("sdg16")}
						{...props}
						/>
						
					<Divider />
														
					<ScoreItem icon={SDGIcon17} size={size}
						item={{
							value: item["PARTNERSHIPS_FOR_THE_GOALS Score"],
							rank: item["PARTNERSHIPS_FOR_THE_GOALS Score Rank"],
							topN: item["PARTNERSHIPS_FOR_THE_GOALS Score Top 3"],
							bottomN: item["PARTNERSHIPS_FOR_THE_GOALS Score Bottom 3"]
						}}
						iconInfo={t("sdg17")}
						{...props}
						/>
					<br/>
				</Stack>
			</swiper-slide>
			
		</swiper-container>
	)
	
	
}

export default SwipeableScreen;
