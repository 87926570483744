import i18n from './i18n';
import { Menu, IdleTimeOutHandler } from "./components";
import { Home,
	Ticker,
	AccountManagement,
	Login,
	Help,
	SignUpSuccess,
	Payment ,
	Policy,
	ResetPassword,
	About,
	Onboarding,
	ManageUsers
} from "./views";
import Cookies from "js-cookie";
import { useState, useEffect, useRef, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { post } from "./components/Functions";
import { CssBaseline, Alert, Box } from "@mui/material";
import { createTheme, 
	ThemeProvider,
} from "@mui/material/styles";
import { LocaleContext,
	ThemeContext
} from "./contexts";

const darkMode = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "#81ac1c",
			contrastText: "#fff"
		},
		danger: {
			main: "#ee8032",
			contrastText: "#fff"
		},
	},
	typography: {
		fontFamily: "Roboto Condensed"
	}
})

const lightMode = createTheme({
	palette: {
		mode: "light",
		primary: {
			main: "#81ac1c",
			contrastText: "#fff"
		},
		danger: {
			main: "#ee8032",
			contrastText: "#fff"
		},
		text: {
			primary: "#36657b",
			secondary: "#36657b"
		}
	},
	typography: {
		fontFamily: "Roboto Condensed"
	}
})

function App() {
	const [locale, setLocale] = useState(i18n.language);
	const [theme, setTheme] = useState(false);
	const ref = useRef(null);
	
	const [isActive, setIsActive] = useState(true);
    const [error, setError] = useState(null);
    const [onboardingOpen, setOnboardingOpen] = useState(false);
    
    const nbLoaded = 1;
			
	useEffect(() => {
		ref.current.ownerDocument.body.scrollTop = 0;
		
		let token = Cookies.get("AIDATAME_AUTH_TOKEN")
		if (token) {
			post("/api/user/auth", {token: token})
			.then(res => {
				if (!res.isError) {
					
					let user = res.data;
					delete user["password"]
					localStorage.setItem("user", JSON.stringify(user))
					setError(null)
				} else {
					setError(res.error)
					console.log(res.error)
				}
			})
		} else {
			localStorage.removeItem("user")
		}
		
		let onboardingCookie = Cookies.get("AIDATAME_ONBOARDING_COOKIE");
		if (!onboardingCookie) {
			setOnboardingOpen(true)
		}
		
	}, [])
	
		
	return (
		<ThemeContext.Provider value={{ theme, setTheme }}>
		<ThemeProvider theme={theme ? darkMode : lightMode}>
		<Box sx={{ pb: 7 }} ref={ref}>
		
		<CssBaseline />
		<LocaleContext.Provider value={{ locale, setLocale }}>
		<Suspense fallback={<>Loading..</>}>
		
			<BrowserRouter>
			
			<Routes>
				<Route exact path="/" element={<Home />} />
				<Route path="/:market/:ticker" element={<Ticker />} />
				<Route path="/account" element={<AccountManagement locale={locale}/>} />
				<Route path="/login" element={<Login isMember={true}/>} />
				<Route path="/sign-up" element={<Login isMember={false}/>} />
				<Route path="/sign-up-success" element={<SignUpSuccess />} />
				<Route path="/password/:email" element={<ResetPassword />} />
				<Route path="/help" element={<Help locale={locale}/>} />
				
				<Route path="/payment" element={<Payment locale={locale}/>}/>
				<Route path="/about" element={<About />}/>
				<Route path="/terms-of-service" element={<Policy policyId={"e069beef-d2b5-474a-a84c-3613fa9dbdae"} title={"Terms of service"}/>}/>
				<Route path="/privacy-policy" element={<Policy policyId={"a7f21c25-0c6d-45a4-a799-fc7b7b24f96f"} title={"Privacy Policy"}/>}/>
				<Route path="/cookie-policy" element={<Policy policyId={"1b169ded-34eb-4dac-9c43-e14997e76c75"} title={"Cookie Policy"}/>}/>
				<Route path="/admin" element={<ManageUsers />}/>
			</Routes>
			
			<Menu setTheme={setTheme} 
					setLocale={setLocale}
					theme={theme}
					locale={locale}
					/>
			
			<Onboarding		
				open={onboardingOpen}
				locale={locale}
				/>
				
			</BrowserRouter>
		
		</Suspense>
		<IdleTimeOutHandler 
			onActive={()=>{setIsActive(true)}} 
			onIdle={()=>{setIsActive(false)}}
			/>
		</LocaleContext.Provider>
		</Box>
		</ThemeProvider>
		</ThemeContext.Provider>
	);
}

export default App;
