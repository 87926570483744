import {
	Typography,
	Chip,
	Alert,
	Link
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { useTranslation, Trans } from "react-i18next";

function ScoreDescription({rank, topN, bottomN, info, ...props}) {
	const navigate = useNavigate();
	const { t } = useTranslation();
	
	const handleNavigate = (i) => {
		navigate("/" + props.market + "/" + i)
	}
	

	return (
		<div align="left" sx={{ paddingTop: 10}}>
		{info
			? info
			: <></> 
		}
		{props.status===201 && !rank
			? <Alert severity="info" 
				variant="outlined"
				sx={{ marginTop: 1, marginBottom: 1, marginLeft: 2}}
				>
				<Trans i18nKey="availableToPremUser" 
					ns="translation"
					t={t}
					components={{
						Here: <Link href={"/payment"}>here</Link>,
					}}
					>
				</Trans>
			</Alert>
			: <><Typography variant="subtitle2">
				{t("rankInSector")}: {rank
					? rank
					: props.status===201 ? <></> : t("unranked")
				}
			</Typography>
			<Typography variant="subtitle2">
				Top 3&nbsp;&nbsp;
				{topN.map(i => (
					<Chip key={i} label={i}
						variant="outlined" color="primary" size="small"
						onClick={() => handleNavigate(i)}
						/>
					))}
			</Typography>
			<Typography variant="subtitle2">
				Bottom 3&nbsp;&nbsp;
				{bottomN.map(i => (
					<Chip key={i} label={i}
						variant="outlined" color="danger" size="small"
						onClick={() => handleNavigate(i)}
						/>
					))}
			</Typography></>
		}
		
		
		</div>
		)
	
	
	
}

export default ScoreDescription;
