import { GoBackHeader,
} from "../components";
import { 
	List,
	ListItem,
	ListItemText,
} from "@mui/material";


function Profile(props) {
	return (
		<>
		<GoBackHeader title={"Profile"}/>
		
		<List>
		
		<ListItem>
		<ListItemText>
		Email
		</ListItemText>
		</ListItem>
		
		</List>
		</>
	)
}

export default Profile;
