import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import XHR from 'i18next-http-backend';
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
}

i18n
  .use(XHR)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
//  	lng: "en",
	detection: options,
    fallbackLng: "en",
    debug: false,
	supportedLngs: ["en","fr","de"],
	nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
  });

export default i18n;
