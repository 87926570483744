import { Container,
	Input,
	Button,
	Divider,
	InputAdornment,
	IconButton,
	FormControl,
	FormControlLabel,
	InputLabel,
	Checkbox,
	Alert
} from "@mui/material";
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import Logo from "../assets/logo.svg";
import { useContext, useState } from "react";
import LocaleContext from "../contexts/LocaleContext";
import { useTranslation } from "react-i18next";
import { post } from "../components/Functions";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


function Login({isMember, ...props}) {
	let navigate = useNavigate();
	
	
	const { locale } = useContext(LocaleContext);
	const { t } = useTranslation();
	const minLength = 8;
	
    const [email, setEmail] = useState('')
    const [pwd, setPwd] = useState('')
    const [error, setError] = useState(null)
    //const [isLoaded, setIsLoaded] = useState(false)
    const [showPwd, setShowPwd] = useState(false)
    const [rmbMe, setRmbMe] = useState(false)
    	
	const handleLogin = (e, p) => {
		let expire = rmbMe ? 7 : 1
		
		Promise.all([
			post("/api/user/auth", {email: e, password: p})
			.then(res => {
				if (!res.isError) {
					navigate("/")
					let user = res.data;
					delete user["password"]
					localStorage.setItem("user", JSON.stringify(user))
					setError(null)
				} else {
					setError(res.error)
				}
			}),
			post("/api/user/token", {email: e, password: p, expire: expire * 24 * 3600})
			.then(res => {
				if (!res.isError) {
					
					Cookies.set("AIDATAME_AUTH_TOKEN", res.data.token, {expires: expire})
				} else {
					console.log(res.error)
				}
			})
		])
		
		
	}
	
	const handleSignUp = (e, p) => {
		if (!p || p.length<minLength) {
			setError("Your password must contain at least 8 characters.")
		} else {
			post("/api/user/sign-up/token", {email: e, password: p})
			.then(res => {
				if (!res.isError) {
					navigate("/login")
					alert(t("verificationMailSent"))					
					setError(null)
				} else {
					setError(res.error)
				}
			})
		}
	}
	
	const handleGoogleLoginSuccess = (credential) => {
		const userObject = jwt_decode(credential)
		
		let expire = rmbMe ? 7 : 1
		
		const user = {
			email: userObject.email,
			family_name: userObject.family_name,
			given_name: userObject.given_name,
			name: userObject.name,
			email_verified: userObject.email_verified
		}
		localStorage.setItem("user", JSON.stringify(user))
		
		navigate("/")
		Promise.all([
			post("/api/user/auth", user)
			.then(res => {
				if (!res.isError) {
					
					let user = res.data;
					delete user["password"]
					localStorage.setItem("user", JSON.stringify(user))
				} else {
					console.log(res.error)
					
				}
			}),
			post("/api/user/token", {email: userObject.email, email_verified: userObject.email_verified, expire: expire * 24 * 3600})
			.then(res => {
				if (!res.isError) {
					
					Cookies.set("AIDATAME_AUTH_TOKEN", res.data.token, {expires: expire})
				} else {
					console.log(res.error)
				}
			})
		])
		
		
	}
	

	return (
		<Container sx={{position: "fixed", 
					top: "30%", 
					transform: "translate(-50%,-30%)",
					left: "50%",
					width: "100%",
					maxWidth: "400px"}}>
			<div align="center"><Logo height={80}/></div>
			
			<FormControl variant="standard" 
				fullWidth
				>
            
            <InputLabel htmlFor="standard-adornment-password">Email</InputLabel>
			
			<Input
				placeholder="Email"
				type="text"
				onChange={(e) => setEmail(e.target.value.toLowerCase())}
				/>
				
			</FormControl>
			<FormControl fullWidth variant="standard">
            
            <InputLabel htmlFor="standard-adornment-password">{t("password")}</InputLabel>
			
			<Input
				fullWidth={true}
				placeholder={"Password"}
				type={showPwd ? "text" : "password"}
				onChange={(e) => setPwd(e.target.value)}
				endAdornment={
					<InputAdornment position="end" edge="end">
					
					<IconButton onClick={() => setShowPwd(!showPwd)}>
					
						{showPwd
							? <VisibilityIcon />
							: <VisibilityOffIcon />
						}
					</IconButton>
					</InputAdornment>
					}
				/>
			</FormControl>
			<br/>
			{error
				? <Alert severity="error">{error}</Alert>
				: <></>
			}
			
        	<Button variant="text" 
        			size="small"
        			sx={{ textTransform: "none", fontWeight: 400 }}
        			onClick={() => navigate("/password/reset")}
        			>
        		{t("forgotYourPassword")}
    		</Button>
    		
    		<div>
    		<FormControlLabel control={<Checkbox size="small" />} 
					label={t("rmbMe")} 
					size="small"
					checked={rmbMe}
					onChange={() => setRmbMe(!rmbMe)}
					/>
			</div>	
					
			<Button variant="contained" 
				fullWidth={true}
				sx={{marginTop: "20px"}}
				onClick={() => isMember ? handleLogin(email, pwd) : handleSignUp(email, pwd)}
				>
				
				{isMember
					? t("login")
					: t("signUp")
				}
			</Button>
			{isMember
            ? <div sx={{marginTop: "5pt"}}>{t("dontHaveAnAccount")} 
            	<Button variant="text" 
            			size="small"
            			sx={{ textTransform: "none", fontWeight: 400 }}
            			onClick={(e) => navigate("/sign-up")}
            			>
            		{t("signUp")}
        		</Button>
            </div>
            : <div sx={{marginTop: "5pt"}}>{t("alreadyHaveAnAccount")} 
            	<Button variant="text" 
            			size="small"
            			onClick={(e) => navigate("/login")} >
            		Login
        		</Button>
        		</div>
			}
			
			<Divider sx={{marginTop: "15pt", marginBottom: "15pt"}}>{t("or")}</Divider>
			<GoogleLogin
				onSuccess={res => {
					handleGoogleLoginSuccess(res.credential);
					
				}}
				onError={res => {
					console.log(res);
				}}
				locale={locale}
				text={"continue_with"}
				width="366px"
				/>
			
		</Container>
	)
}

export default Login;
