import { CircularProgress,
	SvgIcon,
	Tooltip,
	IconButton
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';


function ScoreIcon({icon, info, onClick, size=100, ...props}) {

	return (
		
		<div onClick={onClick}
			style={{ position: "relative",
				width: size,
				height: size,
				marginTop: 10,
				marginBottom: 10
			}}
			>
			<div style={{ 
					position: "absolute",
					top: 0,
					left: 0,
				}}
				>
				
			<CircularProgress 
				variant="determinate"
				value={100}
				size={size}
				sx={{color: (value) => ((props.value!==undefined && props.value!==null)
					? props.value<34 
						? "rgba(209, 45, 42, 0.2)" 
						: props.value<67 
							? "rgba(250, 229, 78, 0.2)" 
							: "rgba(78, 162, 65, 0.2)"
					: "rgba(207, 209, 210, 1)"
					)
					
				}}
				
				/>
			<CircularProgress 
				variant="determinate"
				value={props.value}
				size={size}
				style={{ transform: "scaleX(-1) rotate(-90deg)" }}
				sx={{
					position: "absolute",
					left: 0,
					color: (value) => (props.value<34 
						? "#d12d2a" 
						: props.value<67 
							? "#fae54e" 
							: "#4ea241")
				}}
				/>
			<SvgIcon component={icon} inheritViewBox
				sx={{ fontSize: parseInt(size * 1.35),
					position: "absolute",
					top: -21,
					left: -21,
					filter: (props.value!==undefined && props.value!==null) ? "grayscale(0)" : "grayscale(1)"
				}}
				/>
		{info
			? <Tooltip title={info}
				placement="bottom-start"
				enterTouchDelay={0}
				>
				<IconButton
					sx={{ position: "absolute", right: -25, top: -7 }}
					>
					<InfoIcon sx={{ color: "#81ac1c"}}/>
				</IconButton>
			</Tooltip>
			: <></>
		}
			</div>
			
		</div>
		
	)
}


export default ScoreIcon;

