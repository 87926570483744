import { SearchBox, Hit } from "../components";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { InstantSearch, 
	InfiniteHits,
	useInstantSearch
} from "react-instantsearch-hooks-web";
import { Stack, 
	Alert, 
	Button 
} from "@mui/material";
import Logo from "../assets/logo.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


const typesenseApiKey = process.env.REACT_APP_TYPESENSE_API_KEY;

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: typesenseApiKey, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: "aidatame.com",
        path: "/typesense",
        protocol: "https"
      }
    ]
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  additionalSearchParameters: {
    query_by: "issuer_ticker,name",
  }
});

function NoResultsBoundary({ children }) {
	const { indexUiState, results } = useInstantSearch();

	if (!indexUiState.query) {
		return <></>
	} else if (!results.__isArtificial && results.nbHits === 0) {
		return <p>No results.</p>;
	} else {
		return children;
	}
}


function Home(props) {
	const { t } = useTranslation();
	let navigate = useNavigate();
	let user = JSON.parse(localStorage.getItem("user"));

	return (
	<div>
		{/*<Alert severity="warning">This app is under Beta testing.</Alert>*/}
		<Stack 
			container
			spacing={0}
			direction="column"
			alignItems="center"
			>
			<Logo height={150}/>
			<InstantSearch 
				indexName="info" 
				searchClient={typesenseInstantsearchAdapter.searchClient}
				>
				<SearchBox 
					
					/>
					
				<NoResultsBoundary >
					<InfiniteHits hitComponent={Hit} 
						showPrevious={false}
						style={{ overflowY: "scroll", 
							height: "250pt",
							minWidth: "350px",
							marginTop: "5pt"}}
						/>
				</NoResultsBoundary>
			</InstantSearch>
			
			<Stack 
				spacing={1}
				direction="column"
				alignItems="center"
				sx={{ position: "fixed", 
						bottom: 70, 
						width: "100%",
						paddingLeft: 1,
						paddingRight: 1
					}}
				>
			{user
				? <Button variant="contained" 
						fullWidth
						onClick={() => navigate("/payment")}
						>
					{t("seeAvailablePlans")}
					</Button>
				: <>
					<Button variant="outlined" 
						fullWidth
						onClick={() => navigate("/login")}
						>
						{t("login")}
					</Button>
					<Button variant="contained" 
						fullWidth
						onClick={() => navigate("/sign-up")}
						>
					{t("signUp")}
					</Button>
				</>
			}
			
				
			</Stack>
		</Stack>
	</div>
	)    
}

export default Home;
