import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Divider,
	IconButton,
	Grid,
	Typography 
} from "@mui/material";

function GoBackHeader({title, ...props}) {
	let navigate = useNavigate();
	
	
	return (
		<>
		<Grid container spacing={2} columns={2}
 			direction="row"
			>
			<Grid item >
			<IconButton onClick={() => navigate(-1)}>
				<ArrowBackIcon />
			</IconButton>
			</Grid>
			<Grid item 
				sx={{
					position: "absolute", 
					left: "50%", 
					transform: "translate(-70%, 10%)"
				}}
				>
				
			<Typography variant="h6" 
				>
				{title}
			</Typography>
			</Grid>
		</Grid>
			<Divider />
		</>
		
	)
}

export default GoBackHeader;
