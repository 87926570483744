import { useSearchBox } from "react-instantsearch-hooks-web";
import { useState, useRef } from "react";
import { Paper,
	InputBase, 
	IconButton
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";

function SearchBox (props) {
	const { t } = useTranslation();
	const { query, refine } = useSearchBox(props);
	const [inputValue, setInputValue] = useState(query);
	const inputRef = useRef(null);
	
	const setQuery = (newQuery:string) => {
		setInputValue(newQuery);
		refine(newQuery);
	}
	
	if (query !== inputValue && inputRef.current?.isFocused) {
		setInputValue(query);
	}
	
	return (
	
		<Paper
			component="form"
			sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', minWidth: 350 }}
			>
			<IconButton type="button" sx={{ p: '10px' }} aria-label="search">
			<SearchIcon />
			</IconButton>
			
			<InputBase
				ref={inputRef}
				sx={{ ml: 1, flex: 1 }}
				placeholder={t("search")}
				value={inputValue}
				onChange={(e) => {
					setQuery(e.currentTarget.value)
				}}
				/>
		</Paper>
	)
}

export default SearchBox;
