import { GoBackHeader } from "../components";
import { Stack, 
	Typography,
	Link
} from "@mui/material";
import { useTranslation } from "react-i18next";



function About(props) {
	const { t } = useTranslation();
	
	return (
	<>
	<GoBackHeader title={t("about")}/>
	<Stack sx={{ marginTop: 5, marginLeft: 3}} spacing={3}>
	<Typography variant="h4">AIDATACHAMPIONS GmbH</Typography>
	
	<div>
	<div>Julius-Hatry-Straße 1</div>
	<div>68163 Mannheim</div>
	<div>Deutschland</div>
	</div>
	
	<div>
	<p>Telefon: +49 621 15029450</p>

	<p>Mail: kontakt@aidatachampions.com</p>

	<p>Geschäftsführer: Marcus Göhler, Berit Katharina Muth</p>

	<p>Handelsregister: Amtsgericht Mannheim, HRB 749898</p>

	<p>Umsatzsteuer-Identifikationsnummer(n): DE351598856</p>

	<p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden <Link href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</Link>. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.</p>
	</div>
	
	</Stack>
	</>
	)
}

export default About;
