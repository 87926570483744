import { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/favicon.svg";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import SettingSidebar from "./SettingSidebar";

import { BottomNavigation, 
	BottomNavigationAction,
	Button,
	SwipeableDrawer,
	Paper
} from "@mui/material";

function Menu(props) {
	const pathname = window.location.pathname
	const [value, setValue] = useState(pathname);
	const [isOpen, setIsOpen] = useState(false);
		
	const handleChange = (newValue) => {
		setValue(newValue)
	}

	return (
		<Paper sx={{position: "fixed", 
					bottom: 0, 
					left: 0, 
					right: 0,
					zIndex: 1000
				}} 
			elevation={4}
			>
			
			<BottomNavigation	
				value={value}			
				onChange={handleChange}
				>
					<BottomNavigationAction 
						icon={<Logo height={45}/>}
						component={Link}
						to="/"
						 />
						 
					 <BottomNavigationAction 
						icon={<AccountCircleOutlinedIcon />}
						component={Link}
						to="/account"
						 />
						 
					 <BottomNavigationAction 
						icon={<MedicalServicesOutlinedIcon />}
						component={Link}
						to="/help"
						 />
						 
					<BottomNavigationAction 
						icon={<SettingsOutlinedIcon />}
						component={Button}
						onClick={(e) => setIsOpen(!isOpen)}
						 />
						 
			<SwipeableDrawer
				open={isOpen}
				anchor="right"
				onClose={(e) => setIsOpen(false)}
				onOpen={(e) => setIsOpen(true)}
				>
				<SettingSidebar {...props}/>
			</SwipeableDrawer>
					
			</BottomNavigation>
		</Paper>
		
	)
  
}

export default Menu;
