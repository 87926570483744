import { useEffect } from "react";
import { GoBackHeader } from "../components";

function Policy({policyId, title, ...props}) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
  	<>
	<GoBackHeader title={title}/>
	
	<div
		name="termly-embed"
		data-id={policyId}
		data-type="iframe"
		>
	</div>
    </>
  );
}

export default Policy;
